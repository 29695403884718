import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useCallback } from 'react';
import { Playground, Props } from 'docz';
import { ProgressBar, Button, ButtonGroup } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "progress-bar"
    }}>{`Progress Bar`}</h1>
    <p>{`진행 상황의 정도를 보여주기 유용한 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ProgressBar} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ProgressBar value={30} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      useCallback,
      Playground,
      Props,
      ProgressBar,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar value={30} mdxType="ProgressBar" />
    </Playground>
    <h2 {...{
      "id": "animation"
    }}>{`Animation`}</h2>
    <Playground __position={2} __code={'() => {\n  const [value, setValue] = useState(10)\n  const handleClickToIncrease = useCallback(() => {\n    if (value < 100) {\n      setValue(value => value + 10)\n    }\n  }, [value])\n  const handleClickToDecrease = useCallback(() => {\n    if (value > 0) {\n      setValue(value => value - 10)\n    }\n  }, [value])\n  return (\n    <React.Fragment>\n      <ProgressBar value={value} />\n      <br />\n      <ButtonGroup>\n        <Button onClick={handleClickToIncrease}>+ 10%</Button>\n        <Button onClick={handleClickToDecrease}>- 10%</Button>\n      </ButtonGroup>\n    </React.Fragment>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useCallback,
      Playground,
      Props,
      ProgressBar,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState(10);
        const handleClickToIncrease = useCallback(() => {
          if (value < 100) {
            setValue(value => value + 10);
          }
        }, [value]);
        const handleClickToDecrease = useCallback(() => {
          if (value > 0) {
            setValue(value => value - 10);
          }
        }, [value]);
        return <React.Fragment>
        <ProgressBar value={value} mdxType="ProgressBar" />
        <br />
        <ButtonGroup mdxType="ButtonGroup">
          <Button onClick={handleClickToIncrease} mdxType="Button">+ 10%</Button>
          <Button onClick={handleClickToDecrease} mdxType="Button">- 10%</Button>
        </ButtonGroup>
      </React.Fragment>;
      }}
    </Playground>
    <h2 {...{
      "id": "custom-example"
    }}>{`Custom example`}</h2>
    <Playground __position={3} __code={'<ProgressBar value={80} backgroundColor=\"pink\" barColor=\"red\" height={8} />'} __scope={{
      props,
      DefaultLayout,
      useState,
      useCallback,
      Playground,
      Props,
      ProgressBar,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar value={80} backgroundColor="pink" barColor="red" height={8} mdxType="ProgressBar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      